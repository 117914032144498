import React, { useState } from "react";
import "./styles.css";

const SlideElement = ({ children, visible, velocity }) => {
  return visible ? (
    <div
      className="slideLeft end"
      style={{ transition: `all linear ${velocity}s` }}
    >
      {children}
    </div>
  ) : (
    <div
      className="slideLeft start"
      style={{ transition: `all linear ${velocity}s` }}
    >
      {children}
    </div>
  );
};

export const Slide = ({ children, timeout, velocity, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  const someFunc = () => {
    setIsVisible(true);
  };

  setTimeout(someFunc, timeout);

  return (
    <div className={className}>
      <SlideElement visible={isVisible} velocity={velocity}>
        {children}
      </SlideElement>
    </div>
  );
};

const FadeElement = ({ children, visible, velocity }) => {
  console.log(velocity);

  return visible ? (
    <div className="fade end" style={{ transition: `all linear ${velocity}s` }}>
      {children}
    </div>
  ) : (
    <div
      className="fade start"
      style={{ transition: `all linear ${velocity}s` }}
    >
      {children}
    </div>
  );
};

export const Fade = ({ children, timeout, velocity, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  const someFunc = () => {
    setIsVisible(true);
  };

  setTimeout(someFunc, timeout);

  return (
    <div className={className}>
      <FadeElement visible={isVisible} velocity={velocity}>
        {children}
      </FadeElement>
    </div>
  );
};
