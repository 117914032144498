import React from "react";
import "./styles.css";

const EstimatesPage = () => {
  return (
    <div className="estimates">
      <div className="img"></div>
      <div className="content">
        <h1>Free Estimates</h1>
        <p>
          We offer free estimates and onsite design consultation. So if you are
          ready to lay "Siege" to your next project, call 703-507-8486 and have
          our legion at you command!
        </p>
      </div>
    </div>
  );
};

export default EstimatesPage;
