import React from "react";
import "./styles.css";

const BeginningsPage = () => {
  return (
    <div className="beginnings">
      <div className="img"></div>
      <div className="content">
        <h1>Our Humble Beginnings</h1>
        <p>
          Serving the Greater Washington D.C. Metropolitan area since 2012,
          Siege Construction Services was found on the premise to offer turn key
          solutions to our clients. Today, the Siege Construction Staff consists
          of trained and professional employees-- always ensuring Siege
          Construction representation on-site.
        </p>
      </div>
    </div>
  );
};

export default BeginningsPage;
