import React from "react";
import "./styles.css";

const Guarantee = () => {
  return (
    <div className="guarantee">
      <div className="content">
        <h1>Our Guarantee</h1>
        <p>
          We stand behind our work and offer a full one year warranty on our
          product. Our field teams and employees are very approachable and
          communicate immediately with our office staff to submit any change
          orders or quell any issues that may arise during the construction
          process.
        </p>
      </div>
      <div className="img"></div>
    </div>
  );
};

export default Guarantee;
