import React, { Component } from "react";
import "./styles.css";

import Logo from "./siege-logo.png";

import { AiFillPhone, AiOutlineMail, AiFillFacebook } from "react-icons/ai";

const Nav = ({ style, setMenuOpen }) => {
  const toggleMenu = () => {
    console.log("onClick");
    setMenuOpen(true);
  };

  return (
    <div className="nav" style={style}>
      <div>
        <img src={Logo} onClick={toggleMenu} style={{ height: "40px" }} />
      </div>
      <div className="button-container">
        <div
          className="button"
          onClick={() => {
            window.location.href = "tel:7035078486";
          }}
        >
          <AiFillPhone size={24} />
        </div>
        <div
          className="button"
          onClick={() => {
            window.location.href = "mailto:info@siegeconstruction.com";
          }}
        >
          <AiOutlineMail size={24} />
        </div>
        <div
          className="button"
          onClick={() => {
            window.open(
              "https://www.facebook.com/siegeconstructionservices",
              "_blank",
              ""
            );
          }}
        >
          <AiFillFacebook size={24} />
        </div>
      </div>
    </div>
  );
};

export default Nav;
