import React from "react";
import "./styles.css";
import logo from "./siege.png";

import { BsArrowDown as Arrow } from "react-icons/bs";

import { Slide, Fade } from "../../components/animation";

const Home = () => {
  return (
    <div className="home">
      <div className="content">
        <div>
          <div className="title">
            <Slide timeout={1000} velocity={1}>
              <span>Quality</span>
            </Slide>
            <Slide timeout={2000} velocity={1}>
              <span>Communication</span>
            </Slide>
            <Slide timeout={3000} velocity={1}>
              <span>Professionalism</span>
            </Slide>
          </div>
          <Fade className="subtitle" timeout={4000} velocity={2}>
            <span>Scroll down to learn more</span>
          </Fade>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Arrow
          size="40px"
          color="#ffffff88"
          style={{ position: "relative", bottom: "52px" }}
        />
      </div>
    </div>
  );
};

export default Home;

/*
<span style={{ marginTop: "12px" }}>
  <a href="tel:7035078486">(703) 507-8486</a>
</span>
<span>
  <a href="mailto:info@siegeconstruction.com">
    info@siegeconstruction.com
  </a>
</span>
<p style={{ textAlign: "center" }}>
  A Residential and Commericial Construction Services Company. <br />
  Proudly serving the the Greater Washington D.C. Metropolitan Area
  since 2012.
</p>
*/
