// asdf
import React, { useState } from "react";
import "./App.css";

import Section from "./components/section";
import Nav from "./components/nav";

import Home from "./pages/home/";
import BeginningsPage from "./pages/beginnings";
import Guarantee from "./pages/guarantee";
import EstimatesPage from "./pages/estimates";
import About from "./pages/about";
import Services from "./pages/services";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";

import { push as Menu } from "react-burger-menu";

function App() {
  var navHeight = "3em";

  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  var menuStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div id="outer-container" className="app">
      <Menu
        pageWrapId={"page-wrap"}
        outerContainerId="outer-container"
        styles={menuStyles}
        customBurgerIcon={false}
        isOpen={menuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
      >
        <p style={{ color: "white" }}>Coming Soon!</p>
      </Menu>
      <div id="page-wrap" style={{ height: "100%" }}>
        <Nav className="nav" setMenuOpen={setMenuOpen} />
        <div className="main" style={{ height: "100%" }}>
          <Section style={{ backgroundColor: "rgb(7, 33, 73)" }}>
            <Home />
          </Section>
          <Section style={{ backgroundColor: "rgb(19, 51, 101)" }}>
            <BeginningsPage />
          </Section>
          <Section style={{ backgroundColor: "rgb(27, 69, 136)" }}>
            <Guarantee />
          </Section>
          <Section style={{ backgroundColor: "rgb(38, 92, 179)" }}>
            <EstimatesPage />
          </Section>
          {/* <Section>
            <Services />
          </Section>
          <Section>
            <Gallery />
          </Section>
          <Section>
            <Contact />
          </Section> */}
        </div>
      </div>
    </div>
  );
}

export default App;
